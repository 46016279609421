<template>
    <div>
        <!-- Signup -->
        <div class="max-w-7xl mx-auto py-8 lg:pb-6 lg:pt-2 ">
            <div class="px-6 py-6 bg-gradient-to-r from-indigo-600 to-purple-500 rounded-lg md:py-12 md:px-12 lg:py-16 lg:px-16 xl:flex xl:items-center">
            <div class="xl:w-0 xl:flex-1">
                <h2 class="text-2xl font-extrabold tracking-tight text-white sm:text-3xl">
                Inbox Prayer
                </h2>
                <p class="mt-3 max-w-3xl text-lg leading-6 text-indigo-200">
                Receive a different prayer every day during the 30 days.
                </p>
            </div>
            <div class="mt-8 sm:w-full sm:max-w-md xl:mt-0 xl:ml-8">
                <form @submit.prevent="joinEmailList($data)" class="sm:flex">
                <label for="emailAddress" class="sr-only">Email address</label>
                <input v-model.trim="email" @input="delayTouch($v.email)" id="emailAddress" name="emailAddress" type="email" autocomplete="email" required class="w-full border-white px-5 py-3 placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-indigo-700 focus:ring-white rounded-md" placeholder="Enter your email" :disabled="isProcessing">
                <button @click.prevent="joinEmailList($data)" type="submit" class="mt-3 w-full flex items-center justify-center px-5 py-3 border border-transparent shadow text-base font-medium rounded-md text-white bg-indigo-500 hover:bg-indigo-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-indigo-700 focus:ring-white sm:mt-0 sm:ml-3 sm:w-auto sm:flex-shrink-0" :disabled="isProcessing">
                        <span v-if="!isProcessing"  class="ml-1">Notify me</span>
                        <svg v-if="isProcessing" class="animate-spin -ml-1 mr-2 h-5 w-5 text-yellow" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                            <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                        </svg>
                        <span v-if="isProcessing" class="ml-1">Submitting</span>
                </button>
                </form>
                <TWAlert v-if="$v.email.$error && !$v.email.email" alerttype="mini" message="Please enter a valid email address"></TWAlert>
                <p v-if="isSuccess && !isProcessing" class="mt-3 text-base text-green-400 font-semibold">
                Success! You are on the 30-day email prayer list.
                </p>
            </div>
            </div>
        </div>
        <!-- Prayercards -->
        <div class="flex flex-cols md:flex-row">
            <ul role="list" class="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8">
            <!-- Current: "ring-2 ring-offset-2 ring-indigo-500", Default: "focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500" -->

                <li  v-for="prayer in sortedPrayers" :key="prayer.meta.daynumber" class="relative">
                    <div class="focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500 group block w-full aspect-w-10 aspect-h-7 rounded-lg overflow-hidden">
                    <img :src="`/img/unsplash/${prayer.meta.photo}`" alt="" class="opacity-90 group-hover:opacity-75 object-cover pointer-events-none">
                    <button @click="openPrayer(prayer)" type="button" class="absolute inset-0">
                        <h4 class="group-hover:text-gray-200 antialiased font-bold text-white tracking-tight text-2xl md:text-6xl">{{prayer.meta.daynumber}}</h4>
                        <span class="sr-only">{{prayer.meta.title}}</span>
                    </button>
                    </div>
                    <p class="mt-2 block text-sm font-medium text-gray-900 truncate pointer-events-none">{{prayer.meta.title}}
                        <span class="text-sm font-medium text-gray-500 pointer-events-none">Day {{prayer.meta.daynumber}} </span>
                    </p>

                </li>

            </ul>
            <div class="hidden text-center md:flex md:flex-col">
                <calendar :rows="2" :attributes="attrs"  color="indigo" class="hidden md:flex md:ml-6" @dayclick='dayClicked'>
                    <template #day-popover="{ day, format, masks }">
                        <div class="text-xs text-gray-300 font-semibold text-center">
                        {{ format(day.date, masks.dayPopover) }}
                        </div>
                    </template>
                </calendar>
                <span class="mt-3 text-gray-300 text-xs">Created by</span>
                <a href="https://gospel.partners" target="_blank"><img class="h-6 w-auto mx-auto opacity-40	" src="/gospelpartnerswide.png" alt="Gospel.Partners"></a>
            </div>

        </div>
        <PrayerPopup
            v-if="showPrayerWindow"
            @close="togglePrayer"
            @back="navigatePrayer(-1)"
            @next="navigatePrayer(1)"
            :prayer="currentPrayer">
        </PrayerPopup>
    </div>
</template>

<script>
import {mapState,mapActions} from 'vuex'
import calendar from 'v-calendar/lib/components/calendar.umd'
import {required,email} from 'vuelidate/lib/validators'
import TWAlert from './TWAlert.vue'
import PrayerPopup from './PrayerPopup'
const touchMap = new WeakMap() //for delaying error validation
export default {
  name: 'Prayer',
  components: {
      TWAlert,
      PrayerPopup,
      calendar
  },
  data () {
      return {
          email: null,
          showPrayerWindow: false,
          currentPrayer: {},
            attrs: [
                {
                highlight: {
                    start: { fillMode: 'outline' },
                    base: { fillMode: 'light' },
                    end: { fillMode: 'outline' },
                },
                dates: { start: new Date(2024, 2, 11), end: new Date(2024, 3, 10) },
                },
            ],
      }
  },
  validations: {
    email: {
      required,
      email
    }
  },
  created () {
    this.$emit('ready')
  },
  computed: {
      ...mapState('prayer',{
          emailAdded: state => state.email,
          prayers: state => state.items,
          isProcessing: state => state.isProcessing,
          isSuccess: state => state.isSuccess
      }),
      sortedPrayers() {
          return this.prayers.slice(0).sort(function(a,b) {
            return a.meta.daynumber - b.meta.daynumber;
          });
      }
  },
  mounted () {
    this.loadPrayers()
  },
  methods: {
        delayTouch($v) {
            $v.$reset()
            if (touchMap.has($v)) {
                clearTimeout(touchMap.get($v))
            }
            touchMap.set($v, setTimeout($v.$touch, 1000))
        },
        processPrayerList(event) {
            this.$v.$touch
            if(!this.$v.$invalid) {
                this.joinEmailList(event)
            }
        },
        ...mapActions('prayer',{
            loadPrayers : 'loadPrayers',
            joinEmailList : 'joinEmailList'
        }),
        dayClicked(day) {
            const daydiff = Math.round((day.date-this.attrs[0].dates.start)/(1000*60*60*24))
            this.openPrayer(this.sortedPrayers[daydiff])
        },
        togglePrayer() {
            this.showPrayerWindow = !this.showPrayerWindow
            console.log(this.showPrayerWindow)
        },
        openPrayer(prayer) {
            this.currentPrayer = prayer
            this.togglePrayer()
        },
        navigatePrayer(amt) {
            this.currentPrayer = this.sortedPrayers[this.currentPrayer.meta.daynumber-1+amt]
        }
  }
}
</script>

<style scoped>
h4 {
    background: -webkit-linear-gradient(rgb(143, 144, 146), rgb(255, 255, 255));
    background-clip: text;
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: rgb(255, 255, 255);
}
</style>