import Vue from 'vue'
import Router from 'vue-router'
import Home from '@/pages/PageHome'
import Error404 from '@/pages/Error404'
import Login from '@/pages/Login'
import Prayer from '@/components/Prayer'

Vue.use(Router)

const router = new Router({
  routes: [
    {
      path: '/login',
      name: 'Login',
      component: Login
    },
    {
      path: '/',
      component: Home,
      meta: {
        requiresAuth: true
      },
      children: [
        {
          path: '',
          name: 'prayer',
          component: Prayer
        }
      ]
    },
    {
      path: '*',
      name: 'NotFound',
      component: Error404
    }
  ],
  mode: 'history'
})

router.beforeEach((to, from, next) => {
  if (to.name !== 'Login' && !localStorage.getItem("jwt")) next({ name: 'Login' })
  // if (to.matched.some(record => record.meta.requiresAuth)) {
  //   if (!localStorage.getItem("jwt")) {
  //     next({ name: 'Login' })
  //   }
  // }
  else next()
})

export default router