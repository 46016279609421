<template>
  <div class="h-screen bg-white flex">
    <div class="flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-1 lg:px-20 xl:px-14">
      <div class="mx-auto w-full max-w-sm lg:w-96 text-center items-center">
        <div>
          <h1 class="text-6xl font-bold pb-2">
            <span class="bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-purple-500 lowercase">
              MayPray
            </span>
          </h1>
          <span class="text-gray-400">by</span>
          <img class="h-6 w-auto mx-auto opacity-50	" src="/gospelpartnerswide.png" alt="Gospel.Partners">
          <h2 class="mt-2 text-2xl font-bold text-gray-900">
          Enter your prayer list code:
          </h2>
          <!-- <p class="mt-2 text-sm text-gray-600 max-w">
            Or
            <a href="#" class="font-medium text-indigo-600 hover:text-indigo-500">
              start your 14-day free trial
            </a>
          </p> -->
        </div>

        <div class="mt-8">
          <!-- <div>
            <div>


              <div class="mt-1 grid grid-cols-3 gap-3">
                <div>
                  <a href="#" class="w-full inline-flex justify-center py-2 px-4 border border-gray-300 rounded-md shadow-sm bg-white text-sm font-medium text-gray-500 hover:bg-gray-50">
                    <span class="sr-only">Sign in with Facebook</span>
                    <svg class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" aria-hidden="true">
                      <path fill-rule="evenodd" d="M20 10c0-5.523-4.477-10-10-10S0 4.477 0 10c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V10h2.54V7.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V10h2.773l-.443 2.89h-2.33v6.988C16.343 19.128 20 14.991 20 10z" clip-rule="evenodd" />
                    </svg>
                  </a>
                </div>

                <div>
                  <a href="#" class="w-full inline-flex justify-center py-2 px-4 border border-gray-300 rounded-md shadow-sm bg-white text-sm font-medium text-gray-500 hover:bg-gray-50">
                    <span class="sr-only">Sign in with Twitter</span>
                    <svg class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" aria-hidden="true">
                      <path d="M6.29 18.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0020 3.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.073 4.073 0 01.8 7.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 010 16.407a11.616 11.616 0 006.29 1.84" />
                    </svg>
                  </a>
                </div>

                <div>
                  <a href="#" class="w-full inline-flex justify-center py-2 px-4 border border-gray-300 rounded-md shadow-sm bg-white text-sm font-medium text-gray-500 hover:bg-gray-50">
                    <span class="sr-only">Sign in with GitHub</span>
                    <svg class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" aria-hidden="true">
                      <path fill-rule="evenodd" d="M10 0C4.477 0 0 4.484 0 10.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0110 4.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.203 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.942.359.31.678.921.678 1.856 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0020 10.017C20 4.484 15.522 0 10 0z" clip-rule="evenodd" />
                    </svg>
                  </a>
                </div>
              </div>
            </div>

            <div class="mt-6 relative">
              <div class="absolute inset-0 flex items-center" aria-hidden="true">
                <div class="w-full border-t border-gray-300"></div>
              </div>
              <div class="relative flex justify-center text-sm">
                <span class="px-2 bg-white text-gray-500">
                  Or continue with
                </span>
              </div>
            </div>
          </div> -->

          <div class="mt-6">
            <form @submit.prevent="loginProjectCode" class="space-y-6" autocomplete="off">

              <div v-if="wrongPassword" class="bg-yellow-50 border-l-4 border-yellow-400 p-4">
                <div class="flex">
                  <div class="flex-shrink-0">
                    <!-- Heroicon name: exclamation -->
                    <svg class="h-5 w-5 text-yellow-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                      <path fill-rule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clip-rule="evenodd" />
                    </svg>
                  </div>
                  <div class="ml-3 align-left">
                    <p class="text-xs text-yellow-700">
                    Sorry, this isn't a valid code. Check your typing.
                    </p>
                  </div>
                </div>
              </div>

              <div class="space-y-1">
                <div class="mt-1">
                  <input v-model="$v.form.toolcode.$model" name="toolcode" type="password" autocomplete="current-password" required class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                  <div v-if="$v.form.toolcode.$error && !$v.form.toolcode.required" class="error">Code required</div>
                </div>
              </div>

              <div >
                <button @click.prevent="loginProjectCode" class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                Open prayer tool
                </button>
              </div>

              <p class="text-sm font-medium text-gray-700">
              Just a shared prayer list for friends.
              </p>

            </form>
          </div>
        </div>
      </div>
    </div>
    <div class="hidden lg:block flex-1 h-screen w-0">
        <div class="container mx-auto h-full flex justify-center items-center">
          <img class="object-left" src="/img/backgrounds/bg5.svg" alt="">
        </div>

    </div>
  </div>

</template>

<script>
import asyncDataStatus from '@/mixins/asyncDataStatus'
import {required} from 'vuelidate/lib/validators'
export default {
  name: 'Login',
  data () {
    return {
      form: {
        toolcode: null,
      },
      wrongPassword: false
    }
  },
  validations: {
    form: {
      toolcode: {
        required
      },
    }
  },
  mixins: [asyncDataStatus],
  created () {
    this.$emit('ready', true)
    if(this.$route.query.code) {
      this.form.toolcode = this.$route.query.code
      this.loginProjectCode()
    }
  },
  methods: {

      loginProjectCode () {
          if (!this.$v.form.toolcode.$invalid) {
            this.$emit('updateAsyncState', 'pending')
            return this.$http.post("/.netlify/functions/toolcode", this.form)
              .then(response => {
                let token = response.data.token;
                localStorage.setItem("jwt", token);
                if (token) {
                  this.$router.push({name: 'prayer'})
                }
                this.$emit('updateAsyncState', 'success')
              })
              .catch(() => {
                // WRONG PASSWORD?
                this.wrongPassword = true
                this.$emit('updateAsyncState', 'success')
              })
          } else {
            return Promise.reject('Wrong code. Please check with your resource rep for the code.')
          }
        }
  },

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
