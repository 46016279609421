<template>
  <div class="h-screen overflow-visible bg-gray-300">
    <div class="fixed bg-white w-full z-20 shadow print:hidden">
      <div class="max-w-7xl mx-auto flex justify-between items-center px-4 py-5 sm:px-6 sm:py-4 lg:px-8 md:justify-start md:space-x-10">
        <div>
          <h1 class="text-2xl font-bold pb-2">
            <span class="bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-purple-500 lowercase">
              MayPray
            </span>
          </h1>
        </div>
        <div class="-mr-2 -my-2 md:hidden">
          <button type="button" @click="showMobile = !showMobile" class="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
            <span class="sr-only">Open menu</span>
            <!-- Heroicon name: menu -->
            <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" />
            </svg>
          </button>
        </div>
        <div class="hidden md:flex-1 md:flex md:items-center md:justify-between">
          <nav class="flex space-x-10">

          </nav>
          <div class="flex items-center md:ml-12">
                 <span class="font-semibold text-base">30 Days of Prayer: <span class="font-normal text-gray-400">March 10-April 11, 2024</span></span>
            <!-- <a @click="exitTool" class="text-base font-medium text-gray-500 hover:text-gray-900">
            Exit
            </a> -->
            <!-- <button @click="exitTool" class="ml-8 inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700">
            Close
            </button> -->
          </div>
        </div>
      </div>
    </div>

    <!--
      Mobile menu, show/hide based on mobile menu state.

      Entering: "duration-200 ease-out"
        From: "opacity-0 scale-95"
        To: "opacity-100 scale-100"
      Leaving: "duration-100 ease-in"
        From: "opacity-100 scale-100"
        To: "opacity-0 scale-95"
    -->
    <transition
      enter-active-class="duration-200 ease-out"
      enter-from-class="opacity-0 scale-95"
      enter-to-class="opacity-100 scale-100"
      leave-active-class="duration-100 ease-in"
      leave-from-class="opacity-100 scale-100"
      leave-to-class="opacity-0 scale-95"
    >
      <div v-show="showMobile" class="absolute z-30 top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden">
        <div class="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
          <div class="pt-5 pb-6 px-5 sm:pb-8">
            <div class="flex items-center justify-between">
              <div>
                <h1 class="text-2xl font-bold">
                  <span class="bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-purple-500 lowercase">
                    MayPray
                  </span>
                </h1>
              </div>
              <div class="-mr-2">
                <button type="button" @click="showMobile = !showMobile" class="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                  <span class="sr-only">Close menu</span>
                  <!-- Heroicon name: x -->
                  <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </button>
              </div>
            </div>
            <div class="mt-6 sm:mt-8">
              <nav @click="showMobile = !showMobile">
                <div class="grid gap-7 sm:grid-cols-2 sm:gap-y-8 sm:gap-x-4">

                  <!-- <router-link to="help" class="-m-3 flex items-center p-3 rounded-lg hover:bg-gray-50">
                    <div class="flex-shrink-0 flex items-center justify-center h-10 w-10 rounded-md bg-indigo-500 text-white sm:h-12 sm:w-12">
                      <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z" />
                      </svg>
                    </div>
                    <div class="ml-4 text-base font-medium text-gray-900">
                    More Help
                    </div>
                  </router-link> -->


                </div>
                <!-- <div class="mt-8 text-base">
                  <a href="#" class="font-medium text-indigo-600 hover:text-indigo-500"> View all products <span aria-hidden="true">&rarr;</span></a>
                </div> -->
              </nav>
            </div>
          </div>
          <div class="py-6 px-5">

            <div class="mt-6">
              <span>30 Days of Prayer: <span>March 10-April 11, 2024</span></span>

              <button @click="exitTool" class="w-full flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-indigo-600 hover:bg-indigo-700">
              Logout
              </button>
              <!-- <p class="mt-6 text-center text-base font-medium text-gray-500">
                Existing customer?
                <a href="#" class="text-indigo-600 hover:text-indigo-500">
                  Sign in
                </a>
              </p> -->
            </div>
          </div>
        </div>
      </div>
    </transition>

    <main class="overflow-scroll h-screen pt-12 bg-gray-100" >
      <div class="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
        <!-- Replace with your content -->
        <div class="overflow-visible h-screen px-4 py-4 sm:px-0  bg-gray-100 ">

          <router-view @ready="pageReady"></router-view>

        </div>
        <!-- /End replace -->
      </div>
    </main>

  </div>
</template>

<script>
import VueJwtDecode from "vue-jwt-decode";
export default {
  data: () => ({
    showMobile: false,
    showSolutions: false,
    showMore: false,
    user: {}
  }),
  name: 'Template',
  created () {
    this.$emit('ready')
  },
  methods: {
    getUserDetails() {
      let token = localStorage.getItem("jwt");
      let decoded = VueJwtDecode.decode(token);
      this.user = decoded;
    },
    exitTool () {
      localStorage.removeItem('jwt')
      this.$router.push({name: 'Login'})
    },
    pageReady () {
      this.$emit('ready')
    }
  }
}
</script>

<style scoped>
</style>
