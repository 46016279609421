<template>
  <div id="app">
    <router-view v-show="showPage" @ready="pageReady"/>
    <AppSpinner v-show="!showPage">loading...</AppSpinner>
  </div>
</template>

<script>
import NProgress from 'nprogress'
import AppSpinner from './components/AppSpinner'

export default {
  name: 'app',
  components: {
    AppSpinner
  },
  data () {
    return {
      showPage: false
    }
  },
  methods: {
    pageReady () {
      this.showPage = true
      NProgress.done()
    }
  },
  created () {
    NProgress.configure({
      speed: 200,
      showSpinner: false
    })
    NProgress.start()
    this.$router.beforeEach((to, from, next) => {
      this.showPage = false
      NProgress.start()
      next()
    })
  }
}
</script>

<style>
@import '~nprogress/nprogress.css';
</style>
