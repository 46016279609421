import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuelidate from 'vuelidate'
import axios from 'axios'
import store from '@/store/index' 
import filters from './filters';
import './assets/styles/tailwind.css'

const base = axios.create({
  baseURL: "/"
});

Vue.prototype.$http = base;
Vue.use(vuelidate)
Vue.config.productionTip = false
Vue.use(filters);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
