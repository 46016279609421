import axios from 'axios'
export default {
  namespaced:true,
  state: {
    items: [],
    email: null,
    isProcessing: false,
    isSuccess: false
  },
  getters: {
    getPrayers (state) {
      return state.prayers
    },
  },
  actions: {
    loadPrayers ({ commit }) {
      axios
        .get('/.netlify/functions/prayers', {
            headers: {
                'jwt': localStorage.getItem("jwt"),
            }
        })
        .then(response => response.data)
        .then(items => {
          commit('setPrayers', items)
        })
    },
    async joinEmailList ({commit},form) {
      commit('setProcessing', true)
      commit('setSuccess', false)
      await axios
      .post('/.netlify/functions/prayerlistadd', form, {
          headers: {
              'jwt': localStorage.getItem("jwt"),
          }
      })
      .then(response => response.data)
      .then((results) => {
        commit('setProcessing', false)
        //console.log(results)
        if(results[0].status == "success")
          commit('setSuccess', true)
      })
      .catch((err) => {
        commit('setProcessing', false)
        console.log(err)
        //set an error manually here
      })
    }
  },
  mutations: {
    setPrayers(state,items) {
      state.items  = items
    },
    setEmail(state,email) {
      state.email = email
    },
    setSuccess(state,status) {
      state.isSuccess = status
    },
    setProcessing(state,processing) {
      state.isProcessing = processing
    },
  }

}
